import './contact.scss';

// import React from 'react'; orig version
import React, { useContext, useState, useEffect, useRef } from 'react'; // 12.10.21
import * as componentUtility from "../../search/componentUtility"; // 12.10.21
import SearchContext from "../context/SearchContext"; // 12.10.21
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
/* eslint no-console: 0  no-dupe-keys: 0 */

import { UnCryptMailto, linkToUnCryptMailto }  from './securemail';

export type IContactProp = StateProps;

declare global {
		interface Window {
	        linkToUnCryptMailto:any;
	    }
}


export const Contact = (props: IContactProp) => {
	const { account } = props;
{/*	function submitForm(e) {
		this.props.history.push('/search');

	} */}

	// 12.10.21:
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef();
	let activeLanguageSwitch = false;

	// 12.10.21:
	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); activeLanguageSwitch = true; localStorage.setItem('lang', e.currentTarget.value) }}  >{ds}</select>;
	}

	const searchLangSelect = searchLangSelector();

	window.linkToUnCryptMailto = linkToUnCryptMailto

	console.log(UnCryptMailto('nbjmup;jogpAmfjcoj{pqfo/ef'));

	return (
		<Container>
			<Row>
				<Col md="9">
					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a>
				</Col>
				<Col md="3" className="searchmod">

					{searchLangSelect}
				</Col>
			</Row>
			<Row>
				<Col md="12">
{/*					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a> */}
					{localStorage.getItem('lang') === "de" ?
						<h2>Kontakt</h2>
						: <h2>Contact</h2>}

					{localStorage.getItem('lang') === "de" ?
						<div className="lo-contact"><span>
							<h3><b>Allgemeine Informationen</b></h3>
							<h4><a href="javascript:linkToUnCryptMailto('nbjmup;jogpAmfjcoj{pqfo/ef')">info@leibnizopen.de</a></h4>
							<h3><b>Ansprechpartner/innen für Leibniz-Einrichtungen</b></h3>
							<h4>Sektion A: <a href="javascript:linkToUnCryptMailto('nbjmup;bhbuif/hfcfsuAhftjt/psh!');" title="Opens window for sending email">Dr. Agathe Gebert (GESIS)</a></h4>
							<h4>Sektion B: <a href="javascript:linkToUnCryptMailto('nbjmup;s/gmpisA{cx/fv');" title="Opens window for sending email">Ralf Flohr (ZBW)</a>, <a href="javascript:linkToUnCryptMailto('nbjmup;bhbuif/hfcfsuAhftjt/psh!');" title="Opens window for sending email">Dr. Agathe Gebert (GESIS)</a></h4>
							<h4>Sektion C: <a href="javascript:linkToUnCryptMailto('nbjmup;bsojohA{cnfe/ef');" title="Opens window for sending email">Prof. Dr. Ursula Arning (ZB MED)</a></h4>
							<h4>Sektion D: <a href="javascript:linkToUnCryptMailto('nbjmup;dpsjoob/tdiofjefsAujc/fv');" title="Opens window for sending email">Corinna Schneider (TIB)</a></h4>
							<h4>Sektion E: <a href="javascript:linkToUnCryptMailto('nbjmup;bsojohA{cnfe/ef');" title="Opens window for sending email">Prof. Dr. Ursula Arning (ZB MED)</a>, <a href="javascript:linkToUnCryptMailto('nbjmup;dpsjoob/tdiofjefsAujc/fv');" title="Opens window for sending email">Corinna Schneider (TIB)</a></h4>
						</span></div>
						:<div className="lo-contact"><span>
							<h3><b>General information</b></h3>
							<h4><a href="javascript:linkToUnCryptMailto('nbjmup;jogpAmfjcoj{pqfo/ef')">info@leibnizopen.de</a></h4>
							<h3><b>Contact persons for Leibniz institutions</b></h3>
							<h4>Sektion A: <a href="javascript:linkToUnCryptMailto('nbjmup;bhbuif/hfcfsuAhftjt/psh!');" title="Opens window for sending email">Dr. Agathe Gebert (GESIS)</a></h4>
							<h4>Sektion B: <a href="javascript:linkToUnCryptMailto('nbjmup;s/gmpisA{cx/fv');" title="Opens window for sending email">Ralf Flohr (ZBW)</a>, <a href="javascript:linkToUnCryptMailto('nbjmup;bhbuif/hfcfsuAhftjt/psh!');" title="Opens window for sending email">Dr. Agathe Gebert (GESIS)</a></h4>
							<h4>Sektion C: <a href="javascript:linkToUnCryptMailto('nbjmup;bsojohA{cnfe/ef');" title="Opens window for sending email">Prof. Dr. Ursula Arning (ZB MED)</a></h4>
							<h4>Section D: <a href="javascript:linkToUnCryptMailto('nbjmup;dpsjoob/tdiofjefsAujc/fv');" title="Opens window for sending email">Corinna Schneider (TIB)</a></h4>
							<h4>Section E: <a href="javascript:linkToUnCryptMailto('nbjmup;bsojohA{cnfe/ef');" title="Opens window for sending email">Prof. Dr. Ursula Arning (ZB MED)</a>, <a href="javascript:linkToUnCryptMailto('nbjmup;dpsjoob/tdiofjefsAujc/fv');" title="Opens window for sending email">Corinna Schneider (TIB)</a></h4>
						</span></div>}
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Contact);

function newFunction() {
    return window.location;
}
