import React  from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import About from 'app/modules/about/about';
import FAQ from 'app/modules/faq/faq'; 
import Contact from 'app/modules/contact/contact';
import Leibniz from 'app/modules/leibniz/leibniz';
import Technical from 'app/modules/technical/technical';
import Help from 'app/modules/help/help';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import Search from 'app/search/search-component';
import Browse from 'app/search/browse-component';
import Publication from 'app/search/publication-component';

const Account = Loadable({
	loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
	loading: () => <div>loading ...</div>
});

const Admin = Loadable({
	loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
	loading: () => <div>loading ...</div>
});

const Routes = () => (
	<div className="view-routes">
		<Switch >
			<ErrorBoundaryRoute path="/login" component={Login} />
			<ErrorBoundaryRoute path="/logout" component={Logout} />
			<ErrorBoundaryRoute path="/account/register" component={Register} />
			<ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
			<ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
			<ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
			<PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
			<PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
			<ErrorBoundaryRoute path="/search" component={Search}  />
			<ErrorBoundaryRoute path="/browse" component={Browse}  />
			<ErrorBoundaryRoute path="/publication" component={Publication} />
			<ErrorBoundaryRoute exact path={`/publication/version/:id`} component={Publication} />
			<ErrorBoundaryRoute path="/" exact component={Home} />
			<ErrorBoundaryRoute path="/leibniz" exact component={Leibniz} />
			<ErrorBoundaryRoute path="/technical" exact component={Technical} />
			<ErrorBoundaryRoute path="/about" exact component={About} />
			<ErrorBoundaryRoute path="/faq" exact component={FAQ} />
			<ErrorBoundaryRoute path="/contact" exact component={Contact} />
			<ErrorBoundaryRoute path="/help" exact component={Help} />
			<PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.USER]} />
			<ErrorBoundaryRoute component={PageNotFound} />
		</Switch>
	</div>
);

export default Routes;
