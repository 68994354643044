import './about.scss';

// import React from 'react'; orig version
import React, { useContext, useState, useEffect, useRef } from 'react'; // 12.10.21
import * as componentUtility from "../../search/componentUtility"; // 12.10.21
import SearchContext from "../context/SearchContext"; // 12.10.21
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
/* eslint no-console: 0  no-dupe-keys: 0 */

export type IAboutProp = StateProps;

export const About = (props: IAboutProp) => {
	const { account } = props;
{/*	function submitForm(e) {
		this.props.history.push('/search');

	} */}
	
		// 12.10.21:
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef(); 
	let activeLanguageSwitch = false;
	
	// 12.10.21:
	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); activeLanguageSwitch = true; localStorage.setItem('lang', e.currentTarget.value) }}  >{ds}</select>;
	}
	
	const searchLangSelect = searchLangSelector();
	
	return (
		<Container>
			<Row>
				<Col md="9">
					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a>
				</Col>
				<Col md="3" className="searchmod">

					{searchLangSelect}
				</Col>
			</Row>
			<Row>
				<Col md="12">
{/*					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a> */}
					
					{localStorage.getItem('lang') === "de" ?					
						<h2>Über LeibnizOpen</h2>
						: <h2>About LeibnizOpen</h2>}
						
					{localStorage.getItem('lang') === "de" ?
						<p className="lo-about"><span>LeibnizOpen ist das zentrale Open-Access-Portal der <a href="http://www.leibniz-gemeinschaft.de/">Leibniz-Gemeinschaft</a>. Es bietet einen zentralen Zugang zu den frei verfügbaren elektronischen Publikationen der Wissenschaftlerinnen und Wissenschaftler der Leibniz-Gemeinschaft, die ihre Veröffentlichung zur Indexierung in LeibnizOpen autorisiert haben. 
<br /><br />LeibnizOpen enthält Nachweise von Open-Access-Publikationen über alle Fächer und Sektionen hinweg und repräsentiert damit die gesamte Bandbreite sowohl der Themen als auch der Publikationsarten, wie sie in der Leibniz-Gemeinschaft im Kontext von Forschung und Lehre erstellt werden. Es bietet optimale Sicht- und Recherchierbarkeit sowie die dauerhafte Verfügbarkeit des Forschungsoutput der Wissenschaftsgemeinschaft. 
<br /><br />Mit LeibnizOpen setzt die Leibniz-Gemeinschaft insbesondere ihre &quot;<a href="https://www.leibniz-gemeinschaft.de/fileadmin/user_upload/Bilder_und_Downloads/Forschung/Open_Science/Open_Access_Policy_web.pdf" title="Opens external link in new window" target="_blank" rel="noopener noreferrer">Open-Access-Policy 2016-2020</a>&quot; um, die den freien Austausch qualitätsgeprüfter Forschungsergebnisse im Sinne der <a href="http://oa.mpg.de/lang/de/berlin-prozess/berliner-erklarung/"> Berliner Erklärung über den offenen Zugang zu wissenschaftlichem Wissen</a> zum Ziel haben. Das Portal ist wesentlicher Bestandteil einer umfassenden <a href="https://www.leibniz-gemeinschaft.de/forschung/open-science/open-access" title="Opens external link in new window" target="_blank" rel="noopener noreferrer">Open-Access-Strategie der Leibniz-Gemeinschaft</a>.
<br /><br />LeibnizOpen wird durch ein Konsortium der Leibniz-Einrichtungen DIPF, IDS, FIZ Karlsruhe, TIB und ZBW gemeinschaftlich finanziert.
<br /><br />Technisch gesehen ist LeibnizOpen ein Aggregator einer Reihe von Fachrepositorien, die überwiegend von den Einrichtungen der Leibniz-Gemeinschaft betrieben werden, die Informationsinfrastrukturen entwickeln. Sie ermöglichen damit entsprechenden Fach-Communities die Recherche sowie die Archivierung von frei im Netz verfügbaren Publikationen. Dazu werden die Inhalte dieser Repositorien von LeibnizOpen geharvested. Sind für die Inhalte der Publikationen die einzelnen Leibniz-Einrichtungen verantwortlich, so sichern die Fachrepositorien die Qualität und Langzeitarchivierbarkeit der PDFs sowie der Metadaten. 
<br /><br />Weiterführende Informationen finden Sie in unseren <a href="../faq" title="Öffnet internen Link im aktuellen Fenster">FAQ</a> und in den <a href="../leibniz" title="Öffnet internen Link im aktuellen Fenster">Informationen für Leibniz-Einrichtungen</a>. 
<br /><br />LeibnizOpen wird von <a href="http://www.gesis.org/">GESIS – Leibniz-Institut für Sozialwissenschaften</a> technisch betrieben und weiterentwickelt.</span></p>					
					:<p className="lo-about"><span>LeibnizOpen offers a central point of access to freely available digital publications authored by researchers of <a href="http://www.leibniz-gemeinschaft.de/en.html">Leibniz association</a>. It LeibnizOpen contains metadata and full-text links of Open access publications from all disciplines of Leibniz Association. It offers visibility and discoverability as well as long-term availability of the research output of Leibniz association.
<br /><br />LeibnizOpen serves as a means to implement the &quot;<a href="https://www.leibniz-gemeinschaft.de/fileadmin/user_upload/Bilder_und_Downloads/Forschung/Open_Science/Open_Access_Policy_web.pdf" title="Opens external link in new window" target="_blank" rel="noopener noreferrer">Open Access Policy of the Leibniz Association 2016-2020</a>&quot; (in German), which aims to facilitate free exchange of the quality-assured research results as defined the <a href="https://openaccess.mpg.de/Berlin-Declaration"> &quot; Berlin Declaration on Open Access to Knowledge in the Sciences and Humanities &quot;</a>. LeibnizOpen is an integral part of a comprehensive <a href="https://www.leibniz-gemeinschaft.de/en/research/open-science-and-digitalisation/open-access" title="Opens external link in new window" target="_blank" rel="noopener noreferrer">Open Access strategy of Leibniz Association</a>.
<br /><br />LeibnizOpen is jointly funded by a consortium of the Leibniz institutions DIPF, IDS, FIZ Karlsruhe, TIB and ZBW.
<br /><br />Technically speaking, LeibnizOpen is an aggregator of several disciplinary repositories operated by institutions of Leibniz Association that provide information infrastructures. They enable their research communities discovery and archiving of freely available digital publications. To this end, LeibnizOpen harvests the contents of these repositories. Leibniz institutions are responsible for published contents. The operating bodies of the repositories provide for technical quality of metadata and full-texts as well as long-term availability and citeability.
<br /><br />For further information, please refer to our <a href="../faq" title="Öffnet internen Link im aktuellen Fenster">FAQ</a> and our <a href="../leibniz" title="Öffnet internen Link im aktuellen Fenster">Information for Leibniz institutions</a>.
<br /><br />LeibnizOpen is technically run and further developed by <a href="http://www.gesis.org/en/home">GESIS – Leibniz-Institut für Sozialwissenschaften</a>.</span></p>}

				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(About);
