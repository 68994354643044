import * as React from "react";

import * as ReactDOM from "react-dom";
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import * as _ from 'lodash';

import parse from 'html-react-parser';
/* eslint no-console: 0  no-dupe-keys: 0 */

/* see curl -X GET "http://localhost:9000/api/languages" */
export const multilingualFields = ["questionItemStatements",
	"questionItemTexts",
	"questionGridTexts",
	"subQuestionTexts",
	"questionItemNumbers",
	"codelistLabels",
	"studyTitles",
	"questionItemInterviewerInstructions",
	"studyGeographicLocationName",
	"questionGridInterviewerInstructions",
	"questionType",
	"studyGeographicLocationLevelDescription",
	"questionItemNames",
	"questionGridStatements",
	"subQuestionNumbers",
	"instrumentNames",
	"studyDescriptions",
	"instrumentLanguages",
	"publisherNames",
	"studyTopicalCoverage",
	"variableTypes",
	"codelistNames",
	"studyGeographicLocationIdentifier",
	"variableNames",
	"variableDescriptions",
	"codelistDescriptions",
	"variableLabels",
	"questionGridNames",
	"studyGeographicLocationDescription",
	"answerCategories"];

export const langCountryCodes = [
	"en","de"];


export const langCodes = [
	"de",
	"en",
];

export const languageNames = {
   "all":"All languages",
   "en":"English",
   "de":"Deutsch"
}

export function langName(twoLetters) {
	if (twoLetters === "all") { return "All languages" }
	return languageNames[twoLetters];
}
export function txt2Html(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length; i++) {
			htm.push(<span key={'hl-' + i}> {parse(data[i] + '&nbsp;')}</span>);
		}
		return htm;
	}

}

export function txt2HtmlNamelist(data) {

	try {
		if (data === undefined || data.length === 0) {
			return undefined;
		} else {
			const htm = [];
			for (let i = 0; i < data.length-1; i++) {
				htm.push(<span key={'hl-' + i}> {parse(data[i] + ';' + '&nbsp;')}</span>);
			}
			htm.push(<span key={'hl-' + (data.length-1)}> {parse(data[data.length-1] + '&nbsp;')}</span>);
			return htm;
		}
	} catch (error) {
	  alert( error.name );
	  
	}
}

export function creator2Htmlhreflist(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length-1; i++) {
			htm.push(<a href={'../../search?creator[0]=' + parse(data[i]) }> {parse(data[i] + ';' + '&nbsp;')} </a> );
		}
		htm.push(<a href={'../../search?creator[0]=' + parse(data[data.length-1]) }> {parse(data[data.length-1] + '&nbsp;')} </a> );	
		return htm;
	}
	
}

export function subject2Htmlhreflist(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length-1; i++) {
			htm.push(<a href={'../../search?wglsubject[0]=' + parse(data[i]) }> {parse(data[i] + ';' + '&nbsp;')} </a> );
		}
		htm.push(<a href={'../../search?wglsubject[0]=' + parse(data[data.length-1]) }> {parse(data[data.length-1] + '&nbsp;')} </a> );	
		return htm;
	}
	
}

export function subject2Html(data: string) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		htm.push(<a href={'../../search?wglsubject[0]=' + parse(data) }> {parse(data)} </a> );
		return htm;
	}
	
}

export function string2HtmlWgltype(data: string) {

	try {
		if (data === undefined || data.length === 0) {
			return undefined;
		} else {
			const htm = [];
			htm.push(<span key={'hl-0'}> {parse(data + ';' + '&nbsp;')}</span>);
			return htm;
		}
	} catch (error) {
	  alert( error.name );
	  
	}
}

export function kuerzel2Htmlhreflist(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length-1; i++) {
			htm.push(<a href={'../../search?kuerzel[0]=' + parse(data[i]) }> {parse(data[i] + ';' + '&nbsp;')} </a> );
		}
		htm.push(<a href={'../../search?kuerzel[0]=' + parse(data[data.length-1]) }> {parse(data[data.length-1] + '&nbsp;')} </a> );	
		return htm;
	}
	
}

export function txt2HtmlPubllist(data) {

	try {
		if (data === undefined || data.length === 0) {
			return undefined;
		} else {
			const htm = [];
			for (let i = 0; i < data.length-1; i++) {
				htm.push(<span key={'hl-' + i}> {parse(data[i] + ';' + '&nbsp;')}</span>);
			}
			htm.push(<span key={'hl-' + (data.length-1)}> {parse(data[data.length-1] + '&nbsp;' + '[Ed.]')}</span>);
			
			return htm;
		}
	} catch (error) {
	  alert( error.name );
	  
	}

}


export function txt2list(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length; i++) {
			htm.push(<li key={'hl-' + i}> {parse(data[i] + '&nbsp;')}</li>);
		}
		return <ul> {htm} </ul>;
	}

}
export function txt2HtmlBr(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length; i++) {
			let s = <span key={'hl-' + i}> {parse(data[i] + '&nbsp;')}<br /></span>;
			if(data[i].startsWith("http")){
				s = <a href={data[i]}>{s}</a>;
			}
			htm.push(s);
		}
		return htm;
	}

}


export function updateLink(data: string) {

	try {
		if (data === undefined || data.length === 0) {
			return undefined;
		} else {
			const htm = [];
			let url = data;
			if (data.startsWith("urn:nbn")) {
				url = "http://nbn-resolving.de/" + data;
			}
			htm.push(url);
			return htm;
		}
	} catch (error) {
	  alert( error.name );
	  
	}
}

export function langHandler(container, preferredLang, keyPrefix) {
	if (container === undefined) {
		return [];
	}
	if (keyPrefix === undefined) {
		keyPrefix = "k-";
	}
	const langCntryCodes = Object.keys(container);
	if (container === undefined) {
		return undefined;
	}if (container === null) {
		return [];
	}
	// try preferred lang 

	let langBag = container[preferredLang];

	// first fallback any with lang code part matching preferred path
	if (langBag === undefined) {

		const result = langCntryCodes.findIndex((lng) => { return lng.startsWith(preferredLang); }, preferredLang)
		if (result >= 0) {
			const l = langCntryCodes[result];
			langBag = container[l];
		}
	}
	// second fallback to all available languages
	if (langBag === undefined) {
		langBag = [];
		for (let i = 0; i < langCntryCodes.length; i++) {
			if (container[langCntryCodes[i]] !== undefined) {
				if (typeof container[langCntryCodes[i]] === "object") {
					container[langCntryCodes[i]].map((item, index) => {
						langBag.push(<span 
						key={keyPrefix + "-" + i + '-' + langCntryCodes[i] + '-' + index} 
						className="notPrefferedLang" 
						title={"Preferred language " + preferredLang + " not available. Displaying " + langCntryCodes[i]}> 
						{container[langCntryCodes[i]][index]}
						</span>);
					});
				}
				else {
					langBag.push(container[langCntryCodes[i]]);
				}
			}
		}
	}
	if (langBag === undefined) {
		langBag = ["n/a"];
	}
	return langBag;
}




