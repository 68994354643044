import './search.scss';
import './customStyle.scss';

import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, Col, Container } from 'reactstrap';
import * as componentUtility from "./componentUtility";
import ResultListComponent from "./resultList-component.jsx";
import { Helmet } from "react-helmet";

import ReactTooltip from 'react-tooltip';
/* eslint  no-console: 0 */

import {
	PageSizeSelector, InputFilter,
	Toggle, SortingSelector, BoolShould, TermQuery, FilteredQuery,
	SearchkitManager, GroupedSelectedFilters, ResetFilters, RangeFilter, SearchkitProvider, SideBar, SearchBox, Hits, RefinementListFilter, LayoutResults, Layout, LayoutBody, Pagination, PaginationSelect, HitsStats
} from "searchkit";

import appConfig from 'app/config/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchContext from 'app/modules/context/SearchContext';
import AuthContext from 'app/modules/context/AuthContext';
import InputLabel from 'app/search/inputLabel'


const host = appConfig.ELASTICSEARCH_URL;
const searchkitManager = new SearchkitManager(host, { timeout: 20000, createBrowserHistory: true });

const questionTextPaths = ["questionItemTexts.translations", "questionGridTexts.translations"];

const prePostPaths = ["questionItemStatements.translations"];

const studyTitlesPaths = ["studyTitles.translations"];

const responseCategoriesPaths = ["answerCategories.translations"];

let activeLanguageSwitch = false;

const ShortRefinementOption = (props) => (
	<li className={props.bemBlocks.option().state({ selected: props.selected }).mix(props.bemBlocks.container("item"))} onClick={props.onClick}>
		<div className={props.bemBlocks.option("text")} title={props.label}>{props.label.length >= 36 ? props.label.substring(0, 36) : props.label}</div>
		<div className={props.bemBlocks.option("count")}>{props.count}</div>
	</li>
)

function Search(props) {
	const sbox = useRef();
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef();

	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); activeLanguageSwitch = true; localStorage.setItem('lang', e.currentTarget.value); searchkitManager.performSearch(); }}  >{ds}</select>;
	}

	const nested = (query, options) => {		
		const curSearchLang = searchLangSwitch?.current?.selectedOptions[0].value;
		console.log(curSearchLang);
		setSearchLang(curSearchLang);
		if (!query) return {}


		if (options.fields === undefined) {
			const fields = [];
			fields.push("all." + curSearchLang);
			let json = {};
			json = {
				"bool": {
					"should": [
						{ "multi_match": { query, fields } }
					]
				}
			}

			return json;
		} else {
			const fields = [];
			{/* fields.push("all." + curSearchLang); */}
			fields.push("all." + curSearchLang, "title^10", "wglsubject^5", "description^4", "source^4", "title.partial^0.4", "wglsubject.partial^0.3", "description.partial^0.2", "all.en.partial^0.4", "all.de.partial^0.4");
			const newOptionsFields = []
			options.fields.map(f => {
				const s = f.replace(".undefined", "")
				newOptionsFields.push(s)
			})
			options.fields = ["*"]
			

			let json = {};
			json = {
				"bool": {
					"should": [
						{ "multi_match": { query, fields } }
					]
				}
			}
			return json;
		}

	}

	useEffect(() => {
		searchContext.update({ searchLang });
		
		if (activeLanguageSwitch) {
			window.location = window.location.href;
			activeLanguageSwitch = false;
		}
		

	}, [searchLang]);

	useEffect(() => {
		searchContext.update({ searchLang });
		
		if (activeLanguageSwitch) {
			window.location = window.location.href;
			activeLanguageSwitch = false;
		}
	}, []);

	const searchLangSelect = searchLangSelector();


	const removalFn = searchkitManager.addResultsListener((results) => {
		const ids = results.hits.hits.map(function(hit) {
			return hit._id;
		}).join(",")
		searchContext.update({ hitIds: ids });
		removalFn();
	})

	return (
		<SearchkitProvider searchkit={searchkitManager}>
			<Layout size="s" className="search" id="search">
				<Helmet>
					<title>LeibnizOpen: Publications by Leibniz Association</title>

				</Helmet>
				<Container>
					<Row>
						<Col md="3">
							<a href="/">
								<img src="content/images/lopen.png" width="160px" alt="Logo" />

							</a>
						</Col>
						<Col md="6">
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<SearchBox autofocus={true} searchOnChange={true} searchThrottleTime={400} queryOptions={{ "track_total_hits": true }} queryFields={["title^10", "wglsubject^5", "description^4", "source^4", "title.partial^0.4", "wglsubject.partial^0.3", "description.partial^0.2", "all.en.partial^0.4", "all.de.partial^0.4"]} queryBuilder={nested} ref={sbox} />
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<SearchBox translations={{"searchbox.placeholder":"Suche"}} autofocus={true} searchOnChange={true} searchThrottleTime={400} queryOptions={{ "track_total_hits": true }} queryFields={["title^10", "wglsubject^5", "description^4", "source^4", "title.partial^0.4", "wglsubject.partial^0.3", "description.partial^0.2", "all.en.partial^0.4", "all.de.partial^0.4"]} queryBuilder={nested} ref={sbox} />  
								: ""}
								
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<ResetFilters translations={{ "reset.clear_all": "Clear search" }} options={{ query: true, filter: true, pagination: true }} />
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<ResetFilters translations={{ "reset.clear_all": "Suche löschen" }} options={{ query: true, filter: true, pagination: true }} />
								: ""}	

						</Col>
						<Col md="3" className="searchmod">

							{searchLangSelect}
						</Col>

					</Row>
				</Container>
				<Container>_
					<LayoutBody className="row">
						<SideBar className="col-md-3">
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h6>Page size </h6>
								: <h6>Anzahl Einträge </h6>}
							
							<PageSizeSelector options={[10, 50, 100, 500]} listComponent={Toggle} />
							<GroupedSelectedFilters />

							<br />
							
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h6>Sort</h6>
								: <h6>Sortierung </h6>}
							
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<SortingSelector options={[
									{ label: "Relevance", field: "_score", order: "desc" },
									{ label: "Publication date descending", field: "year", order: "desc" },
									{ label: "Publication date ascending", field: "year", order: "asc" },
									{ label: "Title A-Z", field: "title.keyword", order: "asc" },
									{ label: "Title Z-A", field: "title.keyword", order: "desc" }
								]} />
							: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<SortingSelector options={[
									{ label: "Relevanz", field: "_score", order: "desc" },
									{ label: "Veröffentlichungsdatum abnehmend", field: "year", order: "desc" },
									{ label: "Veröffentlichungsdatum zunehmend", field: "year", order: "asc" },
									{ label: "Titel A-Z", field: "title.keyword", order: "asc" },
									{ label: "Titel Z-A", field: "title.keyword", order: "desc" }
								]} />
							: ""}

							<br />
							<br />
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h6>Filters</h6>
							: <h6>Filter</h6>}
							
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<RangeFilter field="year" id="year" min={1891} max={2021} showHistogram={true} title="Published" />
							: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<RangeFilter field="year" id="year" min={1891} max={2021} showHistogram={true} title="Veröffentlicht" />
							: ""}
							
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="section"
									title="Sektion	"
									field={'section.de.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<RefinementListFilter
									id="sectionen"
									title="Section	"
									field={'section.en.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								: ""}
							
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?							
								<RefinementListFilter
									id="wglsubject"
									title="Subject 	"
									field={'wglsubjectInternationalDoten.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?	
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="wglsubject"
									title="Fachrichtung 	"
									field={'wglsubjectInternationalDotde.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}
							
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?							
								<RefinementListFilter
									id="creator"
									title="Author	"
									field={'creator.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />								
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?	
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="creator"
									title="Autor	"
									field={'creator.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}
								
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?							
								<RefinementListFilter
									id="kuerzel"
									title="Code	"
									field={'wglcontributor.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />								
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="kuerzel"
									title="Kürzel	"
									field={'wglcontributor.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />								
								: ""}
								
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?							
								<RefinementListFilter
									id="wgltype"
									title="Document Type 	"
									field={'wgltypeInternationalDoten.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?	
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="wgltype"
									title="Dokumentenart 	"
									field={'wgltypeInternationalDotde.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}	

							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?							
								<RefinementListFilter
									id="subject"
									title="Keyword	"
									field={'subject.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?	
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="subject"
									title="Schlagwort	"
									field={'subject.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}
		
								
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<RefinementListFilter
									id="stateen"
									title="State	"
									field={'state.en.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="state"
									title="Bundesland	"
									field={'state.de.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								: ""}

							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<RefinementListFilter
									id="alphabet"
									title="Alphabet	"
									field={'alphabet.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?	
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="alphabet"
									title="Alphabet	"
									field={'alphabet.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								: ""}
								
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?							
								<RefinementListFilter
									id="contributor"
									title="Editor	"
									field={'contributor.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="contributor"
									title="Herausgeber	"
									field={'contributor.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />								
								: ""}

							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?							
								<RefinementListFilter
									id="type"
									title="Document type	"
									field={'wgltypeInternational.en.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />							
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="type"
									title="Dokumentart	"
									field={'wgltypeInternational.de.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />								
								: ""}
								
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<RefinementListFilter
									id="institutionen"
									title="Institution	"
									field={'institution.en.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<RefinementListFilter
									translations={{"facets.view_more":"Mehr anzeigen", "facets.view_less":"Weniger anzeigen", "facets.view_all":"Alles anzeigen"}}
									id="institution"
									title="Institution	"
									field={'institution.de.keyword'}
									size={3} orderDirection="asc"
									operator={'OR'} />
								: ""}

						</SideBar>
						<LayoutResults className="col-md-9">
							<Row>
								<Col md="12">
									{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
										<h6>Result number: </h6>
									: <h6>Anzahl Ergebnisse: </h6>}
									
									{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?									
										<HitsStats/> 
										: 
										<HitsStats translations={{"hitstats.results_found":"{hitCount} Ergebnisse gefunden in {timeTaken}ms"}} />
									}
																	
									<br/>
									
									{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?             						
              							<h6>Result list: </h6>
              						: <h6>Ergebnisliste: </h6>}
								</Col>
							</Row>
							<Hits hitsPerPage={10} mod="sk-hits-list" className="column"
								listComponent={<ResultListComponent />} />
							<Row>
								<Col md="12">
									{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
										<Pagination pageScope={5} showLast={true} showNumbers={true} />
										:
										<Pagination translations={{"pagination.previous":"Zurück", "pagination.next":"Weiter"}} pageScope={5} showLast={true} showNumbers={true} />
									}
								</Col>
							</Row>
						</LayoutResults>
					</LayoutBody>
				</Container>
			</Layout>
		</SearchkitProvider>
	);
}

export default Search;
