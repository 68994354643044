import './search.scss';
import './customStyle.scss';
import { Link, RouteComponentProps } from 'react-router-dom';

import React, { Component, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import PublicationItem from "./publication-item";

/* eslint no-console: 0 */
import { MatchQuery, SearchkitManager, SearchkitProvider, SearchBox, Hits, LayoutResults, Layout, LayoutBody } from "searchkit";


import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;
let searchkitManager = new SearchkitManager(host);

export class Publication extends Component {

	render() {
		console.info("");
		const publicationEsId = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
		console.log("publicationEsId: " + publicationEsId);
		const location = window.location.href;
		console.info("location: "+ location);
		const url = new URL(location);			
		const queryParams = new URLSearchParams(url.search);
		console.info("queryParams: "+ queryParams.toString());
		console.info("pathname: "+ queryParams.get('lang'));
		const newLang = queryParams.get('lang');
		searchkitManager = new SearchkitManager(host);
		searchkitManager.addDefaultQuery((query) => {
			return query.addQuery(MatchQuery("esid", publicationEsId))
		});

		return (
			<SearchkitProvider searchkit={searchkitManager}>
				<Layout>
					<Helmet>
						<title>LeibnizOpen: Publications by Leibniz Association</title>
					</Helmet>
					<LayoutBody>
						<LayoutResults>
							<SearchBox mod="searchBoxQuestionDetail" searchOnChange={true} />
							<Hits hitsPerPage={1} className="is-12" itemComponent={<PublicationItem key={publicationEsId} qid={publicationEsId} lang={newLang} />} />
						</LayoutResults>
					</LayoutBody>
				</Layout>
			</SearchkitProvider>
		);
	}
}
export default Publication;