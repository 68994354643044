import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import * as componentUtility from "app/search/componentUtility";

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './user-preferences.reducer';
import { IUserPreferences } from 'app/shared/model/user-preferences.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IUserPreferencesUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const UserPreferencesUpdate = (props: IUserPreferencesUpdateProps) => {
	const [userId, setUserId] = useState('0');
	const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

	const { userPreferencesEntity, users, loading, updating } = props;

	const handleClose = () => {
		props.history.push('/user-preferences');
	};

	useEffect(() => {
		if (isNew) {
			props.reset();
		} else {
			props.getEntity(props.match.params.id);
		}

		props.getUsers();
	}, []);

	useEffect(() => {
		if (props.updateSuccess) {
			handleClose();
		}
	}, [props.updateSuccess]);

	const saveEntity = (event, errors, values) => {
		if (errors.length === 0) {
			const entity = {
				...userPreferencesEntity,
				...values,
			};

			if (isNew) {
				props.createEntity(entity);
			} else {
				props.updateEntity(entity);
			}
		}
	};

	return (
		<div>
			<Row className="justify-content-center">
				<Col md="8">
					<h2 id="eqbuiApp.userPreferences.home.createOrEditLabel">
						Choose your preferred content language
					</h2>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col md="8">
					{loading ? (
						<p>Loading...</p>
					) : (
							<AvForm model={isNew ? {} : userPreferencesEntity} onSubmit={saveEntity}>
								{!isNew ? (
									<AvGroup>
										<AvInput id="user-preferences-id" type="text" className="form-control" name="id" required readOnly hidden/>
									</AvGroup>
								) : null}

								<AvGroup>
									<Label id="preferredContentLanguageLabel" for="user-preferences-preferredContentLanguage">
										<Translate contentKey="eqbuiApp.userPreferences.preferredContentLanguage">Preferred Content Language</Translate>
									</Label>
									<AvField name="preferredContentLanguage"
										id="user-preferences-preferredContentLanguage"
										type="select"
										helpMessage="Please enter the language you prefer to be displayed when showing questions. If your prefered language is not available for a question, the question will be displayed in any of its available languages.">
										{componentUtility.langCodes.map(code => (
											<option value={code} key={code}>
												{componentUtility.langName(code)}
											</option>
										))

										}
									</AvField>

								</AvGroup>
								<AvGroup>
									<AvInput id="user-preferences-user" type="select" disabled={true} className="form-control" name="user.id" hidden> 
										<option value="" key="0" />
										{users
											? users.map(otherEntity => (
												<option value={otherEntity.id} key={otherEntity.id}>
													{otherEntity.login}
												</option>
											))
											: null}
									</AvInput>
								</AvGroup>
								<Button tag={Link} id="cancel-save" to="/user-preferences" replace color="info">
									<FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
										<Translate contentKey="entity.action.back">Back</Translate>
									</span>
								</Button>
              &nbsp;
								<Button color="primary" id="save-entity" type="submit" disabled={updating}>
									<FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
								</Button>
							</AvForm>
						)}
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = (storeState: IRootState) => ({
	users: storeState.userManagement.users,
	userPreferencesEntity: storeState.userPreferences.entity,
	loading: storeState.userPreferences.loading,
	updating: storeState.userPreferences.updating,
	updateSuccess: storeState.userPreferences.updateSuccess,
});

const mapDispatchToProps = {
	getUsers,
	getEntity,
	updateEntity,
	createEntity,
	reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferencesUpdate);
