import './search.scss';
import './customStyle.scss';

import { Link } from 'react-router-dom';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import * as utilityComponents from "./componentUtility";
import BootstrapTable from 'react-bootstrap-table-next';
import * as componentUtility from "./componentUtility";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Button } from 'reactstrap';


import * as ReactDOM from "react-dom";
import AuthContext from 'app/modules/context/AuthContext';

/* eslint no-console: 0 */


import appConfig from 'app/config/constants';
import SearchContext from 'app/modules/context/SearchContext';


// need to be set to all!!
function EQBHitItem(props) {

	const searchContext = useContext(SearchContext);

	const { bemBlocks, result } = props




	return (<div className="lo-hit">
		<h3 >
			<a href={`/publication/${result._source.esid}?lang=${searchContext.searchLang}`}>{result._source.title} </a>
{/*		</h3><span className="lo-marginalia">[{componentUtility.txt2HtmlNamelist(result._source.wgltype)}]</span> */}
		</h3><span className="lo-marginalia">[{searchContext.searchLang === "en" ?
							result?._source?.wgltypeInternationalDoten: 
							result?._source?.wgltypeInternationalDotde}]</span>
		<br />
		<span>{componentUtility.txt2HtmlPubllist(result._source.contributor)} {componentUtility.txt2HtmlNamelist(result._source.creator)} - ({result._source.date})</span><br />
		
		{result._source.source ?
			<span> 
				{searchContext.searchLang === "en" ?
					"Source: " : "Quelle: "}								
				{componentUtility.txt2HtmlNamelist(result._source.source)}
				<br />
			</span>
		: ""}
		
		{result._source.publisher ?
			<span> 				
				{searchContext.searchLang === "en" ?
					"Publisher: " : "Verlag: "}					
				{componentUtility.txt2HtmlNamelist(result._source.publisher)}
				<br />
			</span>
		: ""}
					
		<h4>
		<a href={componentUtility.updateLink(result._source.url[0])}>{result._source.url[0]} </a><br /><br />
		</h4>
	</div>)


}
export default EQBHitItem;

