import './help.scss';

// import React from 'react'; orig version
import React, { useContext, useState, useEffect, useRef } from 'react'; // 12.10.21
import * as componentUtility from "../../search/componentUtility"; // 12.10.21
import SearchContext from "../context/SearchContext"; // 12.10.21
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
/* eslint no-console: 0  no-dupe-keys: 0 */
import { useHistory } from "react-router-dom";

export type IHelpProp = StateProps;

export const Help = (props: IHelpProp) => {
	const history = useHistory();
	const { account } = props;
{/*	function submitForm(e) {
		history.push('/search');

	}*/}
	
	// 12.10.21:
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef(); 
	let activeLanguageSwitch = false;
	
	// 12.10.21:
	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); activeLanguageSwitch = true; localStorage.setItem('lang', e.currentTarget.value) }}  >{ds}</select>;
	}
		
	const searchLangSelect = searchLangSelector();
		
	return (
		<Container>
			<Row>
				<Col md="9">
					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a>
				</Col>
				<Col md="3" className="searchmod">

					{searchLangSelect}
				</Col>
			</Row>		
			<Row>
				<Col md="12">
{/*					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a> */}
					
					{localStorage.getItem('lang') === "de" ? <h2>Wie man LeibnizOpen bedient</h2> : <h2>How to use LeibnizOpen</h2>}
					

				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Help);
