import './search.scss';
import './customStyle.scss';

import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, Col, Container } from 'reactstrap';
import * as componentUtility from "./componentUtility";
import BrowseListComponent from "./browseList-component.jsx";
import { Helmet } from "react-helmet";

/* eslint  no-console: 0 */

import {
	PageSizeSelector,
	Toggle, HierarchicalMenuFilter, NumericRefinementListFilter,
	SearchkitManager, GroupedSelectedFilters, ResetFilters, RangeFilter, SearchkitProvider, SideBar, Hits, RefinementListFilter, LayoutResults, Layout, LayoutBody, Pagination, PaginationSelect, HitsStats
} from "searchkit";

import appConfig from 'app/config/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchContext from 'app/modules/context/SearchContext';
import AuthContext from 'app/modules/context/AuthContext';

import Statistics from 'app/search/statistics';

const host = appConfig.ELASTICSEARCH_URL;
const searchkitManager = new SearchkitManager(host, { timeout: 20000, createBrowserHistory: true });


const ShortRefinementOption = (props) => (
	<li className={props.bemBlocks.option().state({ selected: props.selected }).mix(props.bemBlocks.container("item"))} onClick={props.onClick}>
		<div className={props.bemBlocks.option("text")} title={props.label}>{props.label.length >= 36 ? props.label.substring(0, 36) : props.label}</div>
		<div className={props.bemBlocks.option("count")}>{props.count}</div>
	</li>
)

function getApiUrl(language, oldApiUrl) {		
	const newApiUrl = oldApiUrl;
	if (language.searchLang === "de" && oldApiUrl.apiUrl === "api/alphabetInstitutionenEnStatisticsAsHtml") {		
		newApiUrl.apiUrl = "api/alphabetInstitutionenStatisticsAsHtml";
	}
	if (language.searchLang === "en" && oldApiUrl.apiUrl === "api/alphabetInstitutionenStatisticsAsHtml") {		
		newApiUrl.apiUrl = "api/alphabetInstitutionenEnStatisticsAsHtml";
	}
	if (language.searchLang === "de" && oldApiUrl.apiUrl === "api/institutionenSektionenEnStatisticsAsHtml") {		
		newApiUrl.apiUrl = "api/institutionenSektionenStatisticsAsHtml";
	}
	if (language.searchLang === "en" && oldApiUrl.apiUrl === "api/institutionenSektionenStatisticsAsHtml") {		
		newApiUrl.apiUrl = "api/institutionenSektionenEnStatisticsAsHtml";
	}
	if (language.searchLang === "de" && oldApiUrl.apiUrl === "api/institutionenStateEnStatisticsAsHtml") {		
		newApiUrl.apiUrl = "api/institutionenStateStatisticsAsHtml";
	}
	if (language.searchLang === "en" && oldApiUrl.apiUrl === "api/institutionenStateStatisticsAsHtml") {	
		newApiUrl.apiUrl = "api/institutionenStateEnStatisticsAsHtml";
	}
	if (language.searchLang === "de" && oldApiUrl.apiUrl === "api/alphabetKuerzelEnStatisticsAsHtml") {	
		newApiUrl.apiUrl = "api/alphabetKuerzelStatisticsAsHtml";
	}
	if (language.searchLang === "en" && oldApiUrl.apiUrl === "api/alphabetKuerzelStatisticsAsHtml") {		
		newApiUrl.apiUrl = "api/alphabetKuerzelEnStatisticsAsHtml";
	}
	if (language.searchLang === "de" && oldApiUrl.apiUrl === "api/documentTypeEnStatisticsAsHtml") {	
		newApiUrl.apiUrl = "api/documentTypeStatisticsAsHtml";
	}
	if (language.searchLang === "en" && oldApiUrl.apiUrl === "api/documentTypeStatisticsAsHtml") {
		newApiUrl.apiUrl = "api/documentTypeEnStatisticsAsHtml";
	}
	if (language.searchLang === "de" && oldApiUrl.apiUrl === "api/disciplinesEnStatisticsAsHtml") {
		newApiUrl.apiUrl = "api/disciplinesStatisticsAsHtml";
	}
	if (language.searchLang === "en" && oldApiUrl.apiUrl === "api/disciplinesStatisticsAsHtml") {	
		newApiUrl.apiUrl = "api/disciplinesEnStatisticsAsHtml";
	}
	if (language.searchLang === "de" && oldApiUrl.apiUrl === "api/yearEnAsHtml") {
		newApiUrl.apiUrl = "api/yearAsHtml";
	}
	if (language.searchLang === "en" && oldApiUrl.apiUrl === "api/yearAsHtml") {
		newApiUrl.apiUrl = "api/yearEnAsHtml";
	}
	
	return newApiUrl;	
}

function Browse(props) {
	const sbox = useRef();
	if (props.location.search !== undefined && props.location.search !== "") {
		console.info(props.location.search.substring(3))
		console.info(sbox.current);
		console.info(props.location)


	}
	const authContext = useContext(AuthContext);
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef();

	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); localStorage.setItem('lang', e.currentTarget.value) }}  >{ds}</select>;
	}




	/*	useEffect( */
	/*		() => { */
	/*				console.info("search") */
	/*	searchkitManager.performSearch(); */
	/*			}, [authContext.isAdmin, searchLang]); */

	const chl = {
		"fields": {
			"*": {}
		},
		"fragment_size": 255
	}
	const searchLangSelect = searchLangSelector();


	const inLanguage = (fieldArr) => {
		const i18nFieldArr = [];
		fieldArr.map(f => {
			i18nFieldArr.push(f + "." + searchLangSwitch?.current?.selectedOptions[0].value);
		});
		return i18nFieldArr;
	}

	const removalFn = searchkitManager.addResultsListener((results) => {
		const ids = results.hits.hits.map(function(hit) {
			return hit._id;
		}).join(",")
		searchContext.update({ hitIds: ids });
		removalFn();
	})
	
	const queryParams = new URLSearchParams(window.location.search);
	let apiUrl = queryParams.get('apiUrl');
	console.log(apiUrl);
	
	useEffect(() => {
		searchContext.update({ searchLang });
		
		const oldApiUrl = apiUrl;				
		apiUrl = getApiUrl({searchLang}, {apiUrl});		
		if (!(oldApiUrl === apiUrl.apiUrl)) {
			window.location = "/browse/?apiUrl="+ apiUrl.apiUrl;
		}			
	}, [searchLang]);

	useEffect(() => {	
		searchContext.update({ searchLang });
	}, []);
	
	return (
		<SearchkitProvider searchkit={searchkitManager}>
			<Layout size="s" className="search" id="search">
				<Helmet>
					<title>LeibnizOpen: Publications by Leibniz Association</title>

				</Helmet>
				<Container>
					<Row>
						<Col md="3">
							<a href="/">
								<img src="content/images/lopen.png" width="160px" alt="Logo" />
							</a>
						</Col>
						<Col md="3" className="searchmod">

							{searchLangSelect}
						</Col>

					</Row>
				</Container>
				<Container>
					<LayoutBody className="row">
						<SideBar className="col-md-3">
							<GroupedSelectedFilters />
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h4>Browse Leibniz Institutes by:</h4>
								: <h4>Leibniz-Einrichtungen in der Übersicht nach:</h4>}
							<div id="institutes-options">																
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h5><a href="/browse/?apiUrl=api/alphabetInstitutionenEnStatisticsAsHtml">Institutes</a></h5>
								: ""}								
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<h5><a href="/browse/?apiUrl=api/alphabetInstitutionenStatisticsAsHtml">Instituten</a></h5>
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h5><a href="/browse/?apiUrl=api/institutionenSektionenEnStatisticsAsHtml">Sections</a></h5>
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<h5><a href="/browse/?apiUrl=api/institutionenSektionenStatisticsAsHtml">Sektionen</a></h5>
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h5><a href="/browse/?apiUrl=api/institutionenStateEnStatisticsAsHtml">States</a></h5>
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<h5><a href="/browse/?apiUrl=api/institutionenStateStatisticsAsHtml">Bundesländern</a></h5>
								: ""}							
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h5><a href="/browse/?apiUrl=api/alphabetKuerzelEnStatisticsAsHtml">Institute&apos;s Abbreviations</a></h5>
								: ""}																	
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<h5><a href="/browse/?apiUrl=api/alphabetKuerzelStatisticsAsHtml">Institutskürzeln</a></h5>
								: ""}
							</div>
							<br />

							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h4>Browse by metadata field:</h4>
								: <h4>Nach Metadatenfelder:</h4>}
							
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h5><a href="/browse/?apiUrl=api/documentTypeEnStatisticsAsHtml">Document Type</a></h5>
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<h5><a href="/browse/?apiUrl=api/documentTypeStatisticsAsHtml">Dokumentart</a></h5>
								: ""}

							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h5><a href="/browse/?apiUrl=api/disciplinesEnStatisticsAsHtml">Discipline</a></h5>
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<h5><a href="/browse/?apiUrl=api/disciplinesStatisticsAsHtml">Fachrichtung</a></h5>
								: ""}
								
							{searchLangSwitch?.current?.selectedOptions[0].value === "en" ?
								<h5><a href="/browse/?apiUrl=api/yearEnAsHtml">Publication Year</a></h5>
								: ""}
							{searchLangSwitch?.current?.selectedOptions[0].value === "de" ?
								<h5><a href="/browse/?apiUrl=api/yearAsHtml">Erscheinungsjahr</a></h5>
								: ""}

						</SideBar>
						<LayoutResults className="col-md-9">
							<Row>
								<Col md="12">
								{(apiUrl == null || apiUrl.length === 0) ?
									/* <Statistics name="/api/alphabetInstitutionenEnStatisticsAsHtml" /> */
									"" : <Statistics name={apiUrl} />}								
								</Col>
							</Row>


						</LayoutResults>
					</LayoutBody>
				</Container>
			</Layout>
		</SearchkitProvider>
	);
}

export default Browse;
