import './home.scss';

// import React from 'react'; orig version
import React, { useContext, useState, useEffect, useRef } from 'react'; // 12.10.21
import SearchContext from "../context/SearchContext"; // 12.10.21
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

import * as componentUtility from "../../search/componentUtility"
import {
	PageSizeSelector, InputFilter,
	Toggle, SortingSelector, BoolShould, TermQuery, FilteredQuery,
	HitsStats, SearchkitManager, GroupedSelectedFilters, ResetFilters, RangeFilter, SearchkitProvider, SideBar, SearchBox, Hits, RefinementListFilter, LayoutResults, Layout, LayoutBody, Pagination, PaginationSelect
} from "searchkit";

import appConfig from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
/* eslint no-console: 0  no-dupe-keys: 0 */
import { useHistory } from "react-router-dom";

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
	const host = appConfig.ELASTICSEARCH_URL;
	const searchkitManager = new SearchkitManager(host, { timeout: 20000 });
	const history = useHistory();
	const nested = (query, options) => {

		if (!query) return {}

		const curSearchLang = "en";

		if (options.fields === undefined) {
			const fields = [];
			fields.push("all.all");
			for (let i = 0; i < componentUtility.langCodes.length; i++) {
				const field = "all." + componentUtility.langCodes[i];
				fields.push(field);
			}
			let json = {};
			json = {
				"bool": {
					"should": [
						{ "multi_match": { query, fields } }
					]
				}
			}

			return json;
		} else {
			const fields = [];

			const newOptionsFields = []
			options.fields.map(f => {
				const s = f.replace(".undefined", "")
				newOptionsFields.push(s)
			})
			if (newOptionsFields.length === 1 && newOptionsFields[0] === "_all") {
				options.fields = ["all"]
			} else {
				options.fields = newOptionsFields;

			}

			for (let i = 0; i < componentUtility.langCountryCodes.length; i++) {
				for (let j = 0; j < options.fields.length; j++) {
					const field = options.fields[j] + '.' + componentUtility.langCountryCodes[i];
					fields.push(field);
				}
			}
			let json = {};
			json = {
				"bool": {
					"should": [
						{ "multi_match": { query, fields } }
					]
				}
			}
			history.push("/search")
			return json;
		}

	}
	const { account } = props;
	
	// 12.10.21:
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef(); 
	let activeLanguageSwitch = false;
	
	// 12.10.21:
	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); activeLanguageSwitch = true; localStorage.setItem('lang', e.currentTarget.value) }}  >{ds}</select>;
	}
		
	const searchLangSelect = searchLangSelector();	
	
	return (
		<Container>
			<Row>
				<Col md="9">
					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a>
				</Col>
				<Col md="3" className="searchmod">

					{searchLangSelect}
				</Col>
			</Row>		
			<Row>
				<Col md="12">
{/*					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a> */}
			
					{localStorage.getItem('lang') === "de" ?
						<h2>Herzlich willkommen bei LeibnizOpen, dem zentralen Open-Access-Portal der Leibniz-Gemeinschaft.</h2>
						: <h2>Welcome to LeibnizOpen, the central open access gateway of the Leibniz Association.</h2>}
										
					{localStorage.getItem('lang') === "de" ?
						<p className="lead">Suchen sie nach ...?</p>
        				: <p className="lead">Are you looking for ...?</p>}
        			
        			{localStorage.getItem('lang') === "de" ?	
						<p className="lead">Wollen Sie Leibniz-Veröffentlichungen zu bestimmten Themen finden?</p>
						: <p className="lead">Do you want to locate leibniz publications on specific topics?</p>}
						
					<p className="lead">&nbsp;</p>
					
					{localStorage.getItem('lang') === "de" ?
						<p className="lead">Fangen Sie an zu suchen!</p>
						: <p className="lead">Just start searching!</p>}
						
				</Col>
			</Row>
			<Row>

				<Col md="12">
					<SearchkitProvider searchkit={searchkitManager}>
						<Layout size="s" className="search" id="search">
							<SearchBox autofocus={true} searchThrottleTime={400} queryBuilder={nested} />

						</Layout>
					</SearchkitProvider>
				</Col>
			</Row>
{/*			<Row><Col md="12">
				{account && account.login ? (
					<div>
						<Alert color="success">
							<Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
								You are logged in as user {account.login}.
              </Translate>
						</Alert>
					</div>
				) : (
						<div>
							<Alert color="info">
								<Translate contentKey="global.messages.info.register.noaccount">You do not have an account yet?</Translate>&nbsp;
             						 <Link to="/account/register" className="alert-link">
									<Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
								</Link>
							</Alert>
						</div>
					)}

			</Col>

			</Row> */}
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
