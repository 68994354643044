import './faq.scss';

// import React from 'react'; orig version
import React, { useContext, useState, useEffect, useRef } from 'react'; // 12.10.21
import * as componentUtility from "../../search/componentUtility"; // 12.10.21
import SearchContext from "../context/SearchContext"; // 12.10.21
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Button, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
/* eslint no-console: 0  no-dupe-keys: 0 */
import { useHistory } from "react-router-dom";

export type IFAQProp = StateProps;

export const FAQ = (props: IFAQProp) => {
	const history = useHistory();
	const { account } = props;
{/*	function submitForm(e) {
		history.push('/search');

	}*/}
	
	// 12.10.21:
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState((localStorage.getItem('lang') !== undefined && localStorage.getItem('lang') !== "") ? localStorage.getItem('lang') : "en");
	const searchLangSwitch = useRef(); 
	let activeLanguageSwitch = false;
	
	// 12.10.21:
	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value); activeLanguageSwitch = true; localStorage.setItem('lang', e.currentTarget.value) }}  >{ds}</select>;
	}
		
	const searchLangSelect = searchLangSelector();
	
	return (
		<Container>
			<Row>
				<Col md="9">
					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a>
				</Col>
				<Col md="3" className="searchmod">

					{searchLangSelect}
				</Col>
			</Row>
			<Row>
				<Col md="12">
{/*					<a href="/">
						<img src="content/images/lopen.png" width="160px" alt="Logo" />
					</a> */}
					
					{localStorage.getItem('lang') === "de" ? 
					<div id="top">
					<h1>FAQ - Häufige Fragen und Antworten</h1>
					<p className="lo-faq">
					<a href="./faq#c323" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Was ist LeibnizOpen?</a><br />
					<a href="./faq#c321" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Welche Publikationen sind enthalten?</a><br />
					<a href="./faq#c319" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Werden über LeibnizOpen auch Forschungsdaten verfügbar gemacht?</a><br />
					<a href="./faq#c317" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Wer betreibt LeibnizOpen?</a><br />
					<a href="./faq#c315" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Wer steht - neben den Autorinnen und Autoren - hinter den Publikationen?</a><br />
					<a href="./faq#c313" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Wie wird die inhaltliche Qualitätssicherung gewährleistet?</a><br />
					<a href="./faq#c311" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Wie wird Rechtssicherheit gewährleistet?</a><br />
					<a href="./faq#c309" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Unter welchen Bedingungen können die in LeibnizOpen verzeichneten Publikationen genutzt werden (Nutzungsbedingungen)?</a><br />
					<a href="./faq#c307" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Wie erfolgen die Erschließung und Qualitätskontrolle der Metadaten?</a><br />
					<a href="./faq#c305" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Wie werden dauerhafte Verfügbarkeit und Zitierfähigkeit gewährleistet?</a><br />
					<a href="./faq#c303" title="Öffnet internen Link im aktuellen Fenster" className="internal-link"> Wie wird eine möglichst breite Sichtbarkeit der Publikationen gewährleistet?</a><br />
					</p>
					<div id="c323">
					<h3>Was ist LeibnizOpen?</h3>
					<p className="lo-faq"> LeibnizOpen bietet einen zentralen Zugang zu den frei verfügbaren digitalen Publikationen der Wissenschaftlerinnen und Wissenschaftler der <a href="http://www.leibniz-gemeinschaft.de/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">Leibniz-Gemeinschaft</a>. Die Datenbank von LeibnizOpen speist sich aus einer Vielzahl von Volltext-Repositorien, die von Leibniz-Einrichtungen betrieben werden. Mit dem Aufbau von LeibnizOpen wird die <a href="https://www.leibniz-gemeinschaft.de/fileadmin/user_upload/Bilder_und_Downloads/Forschung/Open_Science/Open_Access_Policy_web.pdf" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Open-Access-Policy der Leibniz-Gemeinschaft 2016-2020</a> in die Praxis umgesetz, die den freien Austausch qualitätsgeprüfter Forschungsergebnisse im Sinne der <a href="http://oa.mpg.de/lang/de/berlin-prozess/berliner-erklarung/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">Berliner Erklärung über den offenen Zugang zu wissenschaftlichem Wissen</a> zum Ziel hat.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c321">
					<h3>Welche Publikationen sind enthalten?</h3>
					<p className="lo-faq"> LeibnizOpen nimmt Nachweise über Open-Access-Publikationen von Wissenschaftlerinnen und Wissenschaftlern der Leibniz-Einrichtungen über alle Fächer und Sektionen hinweg auf und macht die dazugehörigen Volltexte unmittelbar zugänglich. Es repräsentiert somit die gesamte Bandbreite der Themen und auch der Publikationsarten, wie sie in der Leibniz-Gemeinschaft im Kontext von Forschung und Lehre erstellt werden.</p>
					<p className="lo-faq">Ziel von LeibnizOpen ist es, möglichst viele der Publikationen, die in anderen Erscheinungsformen bereits publiziert worden sind, als Volltexte im Open Access verfügbar zu machen. Gleichzeitig möchte die Leibniz-Gemeinschaft besonders auch den Zugang zu originären Beiträgen, die an keiner anderen Stelle online veröffentlicht wurden, ermöglichen. Dies gilt u.a. für Reports, Vortragsmanuskripte und Hochschularbeiten – sofern sie von den Leibniz-Einrichtungen autorisiert wurden. In LeibnizOpen sind außerdem Bücher, Aufsätze aus Zeitschriften und Sammelwerken, Forschungsberichte, Working Papers, Hochschulschriften und Konferenzbeiträge enthalten. Zudem werden Beiträge von Leibniz-Forscherinnen und -Forschern, die auf dem goldenen Weg des Open Access erschienen sind, in Repositorien zweitveröffentlicht und in LeibnizOpen nachgewiesen.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c319">
					<h3>Werden über LeibnizOpen auch Forschungsdaten verfügbar gemacht?</h3>
					<p className="lo-faq"> Nein, bislang werden in LeibnizOpen nur Textpublikationen erfasst. Allerdings arbeiten zahlreiche Leibniz-Einrichtungen an <a href="https://www.leibniz-gemeinschaft.de/forschung/open-science/open-und-fair-data" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">Projekten und Services zum Forschungsdatenmanagement (Aufbereitung, Archivierung und Nachweis von Forschungsdaten) sowie zum Data Sharing</a>.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c317">
					<h3>Wer betreibt LeibnizOpen?</h3>
					<p className="lo-faq"> Die technische Plattform von LeibnizOpen wird von der <a href="http://www.leibniz-gemeinschaft.de/ueber-uns/organisation/geschaeftsstelle/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">Geschäftsstelle der Leibniz-Gemeinschaft</a> in Zusammenarbeit mit <a href="http://www.gesis.org/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">GESIS</a> betrieben. Für die Konzeptentwicklung und die Umsetzung von LeibnizOpen ist - im Auftrag des <a href="https://www.leibniz-gemeinschaft.de/forschung/open-science/open-access" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">Arbeitskreises Open Access der Leibniz-Gemeinschaft</a> - eine Arbeitsgruppe verantwortlich, die sich aus Vertreterinnen und Vertretern der an LeibnizOpen angebundenen Repositorien zusammensetzt.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c315">
					<h3>Wer steht - neben den Autorinnen und Autoren - hinter den Publikationen?</h3>
					<p className="lo-faq"> Für die Publikationen, die in LeibnizOpen nachgewiesen werden, sind die jeweiligen Leibniz-Einrichtungen verantwortlich, die die Inhalte publizieren. In den Metadaten jeder in LeibnizOpen verzeichneten Publikation ist die dahinterstehende Leibniz-Einrichtung genannt. Die Leibniz-Einrichtung, von deren Mitarbeiterinnen und Mitarbeitern die Publikationen erstellt wurden, entscheiden darüber, ob diese Publikationen in LeibnizOpen erscheinen.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c313">
					<h3>Wie wird die inhaltliche Qualitätssicherung gewährleistet?</h3>
					<p className="lo-faq"> Der inhaltliche Begutachtungsstatus einer Veröffentlichung entspricht dem jeweils eingesetzten Verfahren des publizierenden Verlages bzw. der herausgebenden Institution (Peer Review, Begutachtungsprozess bei Hochschulschriften etc.).</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c311">
					<h3>Wie wird Rechtssicherheit gewährleistet?</h3>
					<p className="lo-faq"> Die Wahrung der Integrität und Authentizität der Dokumente und die Gewährleistung der Rechtssicherheit auf der Basis des Urheberrechtes und des Datenschutzgesetzes werden bei den beteiligten Leibniz-Einrichtungen vor Ort überwacht. Die Urheberrechte der Autorinnen und Autoren bleiben gewahrt. Die Bereitstellung der Publikationen in den Repositorien sowie der Nachweis in LeibnizOpen stehen einer weiteren Veröffentlichung in Fachzeitschriften und Monographien oder auf anderen Publikationsservern nicht entgegen. Umgekehrt liegt die Einhaltung von betroffenen Urheber- und Verwertungsrechten Dritter in der Verantwortung der Autorinnen und Autoren bzw. der Herausgeberinnen und Herausgeber der Dokumente.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c309">
					<h3>Unter welchen Bedingungen können die in LeibnizOpen verzeichneten Publikationen genutzt werden (Nutzungsbedingungen)? </h3>
					<p className="lo-faq">Die Nutzungsbedingungen jeder Publikation sind in der Vollansicht verlinkt (Feld &quot;Nutzungsbedingungen&quot;). Durch Herunterladen der Publikation erklärt sich die herunterladende Person mit diesen Nutzungsbedingungen einverstanden. Teilweise gelten für die Publikationen je nach Veröffentlichungsvertrag und Repositorium unterschiedliche Bedingungen.</p>
					<p className="lo-faq">Es bestehen erweiterte Nutzungsmöglichkeiten, wenn Publikationen mit einer Creative-Commons-Lizenz zur Verfügung gestellt werden. Die Creative-Commons-Lizenz wird ebenfalls in der Vollansicht unter Nutzungsbedingungen angegeben.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c307">
					<h3>Wie erfolgen die Erschließung und Qualitätskontrolle der Metadaten?</h3>
					<p className="lo-faq"> Die Einrichtungen, die die LeibnizOpen zugrundeliegenden Repositorien betreiben, gewährleisten gemeinsam mit den Ansprechpartnerinnen und Ansprechpartnern in den Leibniz-Einrichtungen, die die Publikationen einspeisen (zumeist Institutsbibliotheken), eine sorgfältige Erfassung und Prüfung der Metadaten, bevor die Dokumente freigeschaltet werden. Die inhaltliche Erschließung der Dokumente mit Keywords, Schlagwörtern und Klassifikationen erfolgt im Allgemeinen durch die Autorinnen und Autoren selbst oder durch deren Institutsbibliotheken.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c305">
					<h3>Wie werden dauerhafte Verfügbarkeit und Zitierfähigkeit gewährleistet?</h3>
					<p className="lo-faq"> Die Zitierfähigkeit der Inhalte wird durch die Vergabe einer dauerhaften und stabilen Adressierung in Form von persistenten Identifikatoren (URN, DOI oder Handles) gewährleistet. Die an LeibnizOpen angebundenen Repositories sind an mehreren nationalen Projekten beteiligt, um die Langzeitarchivierung und dauerhafte Verfügbarkeit der Dokumente sicherzustellen. </p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c303">
					<h3>Wie wird eine möglichst breite Sichtbarkeit der Publikationen gewährleistet?</h3>
					<p className="lo-faq"> Die Publikationen in LeibnizOpen werden in den beteiligten Repositories von Suchmaschinen indexiert. Die Repositories sind darüber hinaus in Fachportale wie <a href="http://www.econbiz.de/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">EconBiz</a>, <a href="https://www.tib.eu/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">TIB-Portal</a>, <a href="https://www.livivo.de/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">LIVIVO</a>, <a href="http://www.fachportal-paedagogik.de/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">Fachportal Pädagogik</a>, <a href="http://linguistik.de/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer">Fachportal Linguistik</a> etc. eingebunden. Auf diese Weise ist die bestmögliche Sichtbarkeit der Inhalte gegeben. Darüber hinaus stehen die Inhalte von LeibnizOpen über eine standardisierte Schnittstelle (OAI-PMH) unter
					<span><a href="../../oaip/oai?verb=Identify" target="_blank" rel="noopener noreferrer"> /oaip/oai?verb=Identify</a></span> externen Datenanbietern (Portale, Repositories) zum Harvesting zur Verfügung.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
			
					</div>		
					:
					<div id="top">
					<h1>Frequently Asked Questions</h1>
					<p className="lo-faq"> 
					<a href="./faq#c324" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">What is LeibnizOpen?</a><br />
					<a href="./faq#c322" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">What content can be accessed?</a><br />
					<a href="./faq#c320" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Will research data also be made available in LeibnizOpen?</a><br />
					<a href="./faq#c318" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Who runs LeibnizOpen?</a><br />
					<a href="./faq#c316" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">Who – alongside with the authors – is behind the publications?</a><br />
					<a href="./faq#c314" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">How is quality control of publications provided for?</a><br />
					<a href="./faq#c312" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">How is legal certainty guaranteed?</a><br />
					<a href="./faq#c310" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">What terms of use for apply to the publications accessible via LeibnizOpen?</a><br />
					<a href="./faq#c308" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">How is indexing and metadata quality in LeibnizOpen provided for?</a><br />
					<a href="./faq#c306" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">How is long-term availability and citability provided for?</a><br />
					<a href="./faq#c304" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">How are publications made as visible as possible?</a><br /><br />
					</p>
					<div id="c324">
					<h3>What is LeibnizOpen?</h3>
					<p className="lo-faq">LeibnizOpen offers a central point of access to freely available digital publications authored by researchers of <a href="http://www.leibniz-gemeinschaft.de/en/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Leibniz association</a>. The LeibnizOpen databank is supplied by a variety of full text repositories run by Leibniz institutions. LeibnizOpen serves as a means to implement the <a href="https://www.leibniz-gemeinschaft.de/fileadmin/user_upload/Bilder_und_Downloads/Forschung/Open_Science/Open_Access_Policy_web.pdf" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">&quot;Open Access Policy of the Leibniz Association 2016-2020&quot;</a> (in German), which aims to facilitate free exchange of the quality assured research results as defined the <a href="http://openaccess.mpg.de/Berlin-Declaration" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">&quot;Berlin Declaration on Open Access to Knowledge in the Sciences and Humanities&quot;</a>.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c322">
					<h3>What content can be accessed?</h3>
					<p className="lo-faq">LeibnizOpen indexes open access publications of Leibniz institutions across all disciplines and sections, making full texts immediately accessible. Its content reflects the whole range of topics and publication types which are generated in the context of research and education activities at the Leibniz institutions. Its objective is to make the greatest number of publications already available in other publication forms accessible in the form of PDFs. At the same time, Leibniz Association intends to facilitate access in particular to original contributions not published online at any other location. This applies (amongst others) to reports, lecture manuscripts and university theses or dissertations, provided that they have been authorized by a Leibniz institution.
					</p>
					<p className="lo-faq">The range of publications in LeibnizOpen includes books, journal contributions and essays, collected works, research reports, working papers, university theses, and conference papers, etc. Leibniz Open also contains secondary publications of contributions by Leibniz researchers first published in Open Access.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c320" className="csc-default">
					<h3>Will research data also be made available in LeibnizOpen?</h3>
					<p className="lo-faq">No, to date only text publications are comprised in LeibnizOpen. Numerous Leibniz institutions are however developing and operating <a href="https://www.leibniz-gemeinschaft.de/en/research/open-science-and-digitalisation/research-data.html" title="Opens external link in a new window" target="_blank" rel="noopener noreferrer" className="external-link-new-window">projects and services related to research data management (processing, archiving and verifying research data) as well as data sharing</a>.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c318" className="csc-default">
					<h3>Who runs LeibnizOpen?</h3>
					<p className="lo-faq">The technical platform of LeibnizOpen is run by the <a href="http://www.leibniz-gemeinschaft.de/en/about-us/organisation/headquarters/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Leibniz Headquarters</a> in cooperation with <a href="http://www.gesis.org/en/home/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">GESIS - Leibniz Institute for the Social Sciences</a>.
					</p>
					<p className="lo-faq">On behalf of the <a href="https://www.leibniz-gemeinschaft.de/en/research/open-science-and-digitalisation/open-access.html" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Working Group Open Access of the Leibniz Association</a>, a working group made up of representatives of the LeibnizOpen repositories is responsible for the concept development and implementation of LeibnizOpen. They also provide <a href="../contact" title="Öffnet internen Link im aktuellen Fenster" className="internal-link">contact persons</a> in order to support participating Leibniz institutions and their researchers. <br /></p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c316" className="csc-default">
					<h3>Who is behind the publications listed in LeibnizOpen (besides the authors)?</h3>
					<p className="lo-faq">Each Leibniz institution is responsible for the contents they authorize for indexing in LeibnizOpen. The issuing Leibniz institution is mentioned in the metadata of each publication indexed in LeibnizOpen. <br /></p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c314" className="csc-default">
					<h3>How is quality control of publications provided for?</h3>
					<p className="lo-faq">The reviewing status of the publication content corresponds to a respective process applied by the publisher or by the issuing or editing institution (peer review, reviewing process in case of university publications etc.).</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c312" className="csc-default">
					<h3>How is legal certainty guaranteed?</h3>
					<p className="lo-faq">Participating Leibniz institutions are responsible for ensuring the integrity and authenticity of the documents and guaranteeing legal certainty on the basis of copyright law and the data protection law. The authors’ copyright remains untouched. The publication of documents in repositories as well as their verification in LeibnizOpen does not conflict with the possibility of further publication in academic journals, monographs, or in other publication servers. Conversely, the authors or editors of the documents are responsible for the non-infringement of copyrights and exploitation rights of third parties.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c310" className="csc-default">
					<h3>What terms of use apply to publications accessible via LeibnizOpen?</h3>
					<p className="lo-faq">The terms of use for each publication in LeibnizOpen are linked in the full record view (field &quot;Terms of Use&quot;). In downloading the publication, the user confirms his or her agreement with the terms of use.</p>
					<p className="lo-faq">Different terms and conditions may apply to publications according to the publishing contract and repository.</p>
					<p className="lo-faq">Further usage possibilities exist for publications made available under a Creative Commons license. In this case, the Creative Commons license is also indicated in the full record view under “Terms of Use”.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c308" className="csc-default">
					<h3>How is indexing and metadata quality in LeibnizOpen provided for?</h3>
					<p className="lo-faq">The institutions maintaining repositories the LeibnizOpen is built upon, jointly with the contact persons at the Leibniz institutions (in most cases the library of the institution), guarantee thorough indexing and control of the metadata prior to document release. Subject indexing of the documents is generally carried out by the authors themselves or by their libraries.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c306" className="csc-default">
					<h3>How is long-term availability and citability provided for?</h3>
					<p className="lo-faq">The long-term availability of the content is ensured by the allocation of permanent and persistent identifiers such as URN, DOI or Handles. The repositories linked with LeibnizOpen take part in various national projects in order to ensure the long term archiving and lasting availability of documents.</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					
					<div id="c304" className="csc-default">
					<h3>How are publications made as visible as possible?</h3>
					<p className="lo-faq">The publications in LeibnizOpen are indexed in the participating repositories by search engines. The repositories are also integrated into subject portals such as <a href="http://www.econbiz.de/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">EconBiz</a>, <a href="https://getinfo.de/app/?&amp;lang=en" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">GetInfo</a>, <a href="https://www.livivo.de/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">LIVIVO</a>, <a href="https://www.fachportal-paedagogik.de/en/start.html" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Fachportal Pädagogik</a>, <a href="https://www.linguistik.de/en/" title="Öffnet externen Link in neuem Fenster" target="_blank" rel="noopener noreferrer" className="external-link-new-window">Fachportal Linguistik</a> etc., which ensures increased visibility. Furthermore, the content of LeibnizOpen is available via a standardized interface (OAI-PMH) at<span><a href="../../../oaip/oai?verb=Identify" target="_blank" rel="noopener noreferrer"> /oaip/oai?verb=Identify</a></span> or harvesting by external data providers (portals, repositories).</p>
					</div>
					<p className="lo-faq"><a href="./faq#top">To top</a></p>
					</div>
					}
					

				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(FAQ);
