import React from 'react'

import axios from 'axios';

export default class Statistics extends React.Component {
  state = {
    statistics: []
  }

  componentDidMount() {	
	axios.get(this.props.name).then(res => {
        const statistics = res.data;
        this.setState({ statistics });
	})		
  }

  render() {	
    return (	
		<div className="lo-about">
		{ this.state.statistics.map((statistic) => {
			
			switch (statistic.firstLevel) {
          		case "All":	return React.createElement("div", { dangerouslySetInnerHTML: { __html: statistic.secondLevelNameEn } });
          		default:	return "";
        	}			
			
		})}		
		</div>
	)
  }
}
