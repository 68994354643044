import React, { useContext, useEffect, useState } from 'react';
import EQBHitItem from "./search-item";
/* eslint no-console: 0 */
import AuthContext from 'app/modules/context/AuthContext';

import SearchContext from 'app/modules/context/SearchContext';
import { getEntities as getPreferredContentLanguage } from 'app/entities/user-preferences/user-preferences.reducer';
import { connect } from 'react-redux';


function ResultListComponent(props) {


	return (
		<div>
			<div className="bbgrey">
			</div>
			{props.hits.map((hit, index) =>
				<EQBHitItem key={index} result={hit} />
			)}
		</div>
	);
}
const mapDispatchToProps = {
	getPreferredContentLanguage
};
connect(mapDispatchToProps)
export default ResultListComponent;