import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import appConfig from 'app/config/constants';

export const BrandIcon = props => (
	<div {...props} className="brand-icon">
		<img src="content/images/lopen.png" alt="Logo" />
	</div>
);

export const Brand = props => (
	<NavbarBrand tag={Link} to="/search" >
		<span className="brand-title">
			<Translate contentKey="global.title">Suche</Translate>
		</span>
		<span className="navbar-version">{appConfig.VERSION}</span>
	</NavbarBrand>
);


export const Page = props => (
	<NavbarBrand tag={Link} to="/browser" >
		<span className="brand-title">
			<Translate contentKey="global.title">Blättern</Translate>
		</span>
		<span className="navbar-version">{appConfig.VERSION}</span>
	</NavbarBrand>
);


export const Home = props => (
	<NavItem>
		<NavLink tag={Link} to="/" className="d-flex align-items-center">
			<FontAwesomeIcon icon="home" />
				{localStorage.getItem('lang') === "en" ?
					<span>Home</span>
					: <span>Heim</span>}
		</NavLink>
	</NavItem>
);

export const About = props => (
	<NavItem>
		<NavLink tag={Link} to="/about" className="d-flex align-items-center">
			{localStorage.getItem('lang') === "en" ?
				<span>About</span>
				: <span>Über</span>}
		</NavLink>
	</NavItem>
);

export const Contact = props => (
	<NavItem>
		<NavLink tag={Link} to="/contact" className="d-flex align-items-center">
			{localStorage.getItem('lang') === "en" ?
				<span>Contact</span>
				: <span>Kontakt</span>}
		</NavLink>
	</NavItem>
);

export const Leibniz = props => (
	<NavItem>
		<NavLink tag={Link} to="/leibniz" className="d-flex align-items-center">
			{localStorage.getItem('lang') === "en" ?
				<span>For Leibniz Institutions</span>
				: <span>Für Leibniz-Einrichtungen</span>}
		</NavLink>
	</NavItem>
);

export const Help = props => (
	<NavItem>
		<NavLink tag={Link} to="/help" className="d-flex align-items-center">
			{localStorage.getItem('lang') === "en" ?
				<span>Help</span>
				: <span>Hilfe</span>}
		</NavLink>
	</NavItem>
);

export const Search = props => (
	<NavItem>
		<NavLink tag={Link} to="/search" className="d-flex align-items-center">
			<FontAwesomeIcon icon="search" />
			{localStorage.getItem('lang') === "en" ?
				<span>Search</span> 
				: <span>Suche</span>}			
		</NavLink>
	</NavItem>
);

export const Browse = props => (
	<NavItem>
		<NavLink tag={Link} to="/browse/?apiUrl=api/alphabetInstitutionenEnStatisticsAsHtml" className="d-flex align-items-center">
			<FontAwesomeIcon icon="search" />
			{localStorage.getItem('lang') === "en" ?
				<span>Browse</span>
				: <span>Blättern</span>}			
		</NavLink>
	</NavItem>
); 
