import './search.scss';
import './customStyle.scss';

import { Link, RouteComponentProps } from 'react-router-dom';
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import * as componentUtility from "./componentUtility";
import Popover from 'react-bootstrap/Popover';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import * as ReactDOM from "react-dom";
import { HashLink } from 'react-router-hash-link';


/* eslint no-console: 0 */
/* eslint complexity: ["error", 45] */


import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;
export class PublicationItem extends Component {

	constructor(props) {
		console.info("");
		super(props)
		this.state = {
			myLang: "de",
			ownLang: props.lang,
			qid: props.qid
		}
		this.langToggle = React.createRef();
		console.info("qid:" + this.state.qid);
		console.info("ownLang:" + this.state.ownLang);
	}

	setMyLang(event) {
		console.info(event);
		this.setState({ myLang: event })
	}

	typed(data, type) {
		const ds = [];
		if (data === undefined) {
			ds.push({ "@type": type, "name": "" });
		} else {
			ds.push({ "@type": type, "name": data });
		}

		return ds;
	}

	langSelector(data) {
		const ds = [];
		data = data.filter((item, index) => data.indexOf(item) === index);
		for (let i = 0; i < data.length; i++) {
			ds.push(<Dropdown.Item onSelect={e => this.setMyLang(data[i])} size="sm" key={data[i]}>{data[i]}</Dropdown.Item>);
		}
		return <DropdownButton className="langsel" id="dropdown-display-lang" title={"Display language: " + this.state.myLang}>
			{ds}
		</DropdownButton>;
	}



	render() {
		const { result } = this.props
		console.info(this.state.qid);
		let jsonld = {
			"@context": "http://schema.org/",
			"identifier": result._source.identifier,
			"sdpublisher": {
				"@type": "Organization",
				"name": "Leibniz-Gemeinschaft ",
				"url": "https://leibnizopen.de/publication" + result._source.esid,
				"logo": "http://www.leibnizopen.de/fileadmin/default/templates/images/favicon.ico",
				"description": "The central open access gateway of the Leibniz Association."
			},
			"@type": "Dataset",
			"@id": result._source.url,
			"name": result._source.title,
			"keywords": result._source.subject + ", " + result._source.wglsubject,
			"datePublished": result._source.oaiDatestamp,
			"description": result._source.description,// langHandler(
			// result._source.description,
			// this.state.myLang ),
			"producer": { "@type": "Organization", "name": result._source.contributor },
			"provider": { "@type": "Organization", "name": result._source.contributor },
			"creator": this.typed(result._source.creator, "Person"),
			"inLanguage": {
				"@type": "Language",
				"name": result._source.language
			}
		};


		jsonld = JSON.stringify(jsonld, null, 2);

		console.log(jsonld)
		console.log('publication-item'+ JSON.stringify(result._source))
		return (<div><Container><Row>
			<Col md="3">
				<a href="/">

					<img src="content/images/lopen.png" width="160px" alt="Logo" />
				</a>

			</Col>
			<Col md="9">
				<h1>{result._source.title}</h1>				
			</Col>
		</Row>
			<Row>
				<Col md="3"></Col>
				<Col md="9">
				</Col>
			</Row>
		</Container>
			<Container className="alternating">
			{result._source.creator ?
				<Row>
					<Col className="caption" md="3">					
						{this.state.ownLang === "en" ?
							"Author" : "Autor/-in"}				
					</Col>
					<Col> <span> {componentUtility.creator2Htmlhreflist(result._source.creator)} </span> </Col>
				</Row>
			: ""}
			{result._source.contributor ? 
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Editor" : "Herausgeber"}
					</Col>
					<Col> <span> {componentUtility.txt2HtmlPubllist(result._source.contributor)} </span> </Col>
				</Row>
			: ""}
			{result._source.url[0] ? 
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Document link" : "Link zum Dokument"}					
					</Col>
					<Col> <a href={componentUtility.updateLink(result._source.url[0])}>{result._source.url[0]}</a> </Col>
				</Row>
			: ""}
			{result._source?.wglcontributor ?
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Organization" : "Einrichtung"}					
					</Col>
					<Col> {componentUtility.kuerzel2Htmlhreflist(result._source?.wglcontributor)} </Col>
				</Row>
			: ""}
			{result._source.publisher ? 
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Publisher" : "Verlag"}					
					</Col>
					<Col> <span> {componentUtility.txt2HtmlNamelist(result._source.publisher)} </span> </Col>
				</Row>
			: ""}
			{result._source.source ?
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Source" : "Quelle"}					
					</Col>
					<Col> {componentUtility.txt2HtmlNamelist(result._source.source)} </Col>
				</Row>
			: ""}
			{result._source.year ?
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Publication year" : "Publikationsjahr"}					
					</Col>
					<Col> {result._source.year} </Col>
				</Row>
			: ""}
			{result._source.wgltype ?
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Document type" : "Dokumentart"}					
					</Col>
					<Col>
						{this.state.ownLang === "en" ?
							result?._source?.wgltypeInternationalDoten: 
							result?._source?.wgltypeInternationalDotde}					
{/*					{componentUtility.txt2HtmlNamelist(result._source.wgltype)} */}
					</Col>
				</Row>
			: ""}
			{result._source.description ?
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Description" : "Zusammenfassung / Abstract"}					
					</Col>
					<Col>
						{this.state.ownLang === "en" ?
							result?._source?.descriptionInternationalDoten: 
							result?._source?.descriptionInternationalDotde}					
{/*					{componentUtility.txt2list(result._source.description)} */}
					</Col>
				</Row>
			: ""}
			{result._source.wglsubject ?
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Subject" : "Fachrichtung"}	
					</Col>
					<Col> 
						{this.state.ownLang === "en" ?
							componentUtility.subject2Html(result?._source?.wglsubjectInternationalDoten): 
							componentUtility.subject2Html(result?._source?.wglsubjectInternationalDotde)}					
{/*					{componentUtility.subject2Htmlhreflist(result._source.wglsubject)} */}
					</Col>				
				</Row>
			: ""}
			{result._source.subject ?
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Keywords" : "Schlagwörter"}					
					</Col>
					<Col>{componentUtility.txt2list(result._source.subject)} </Col>
				</Row>
			: ""}
			{result._source.relation ? 
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Series / Version / No." : "Reihe / Version / Nr."}
					</Col>
					<Col> <span> {result._source.relation} </span> </Col>
				</Row>
			: ""}
			{result._source.language ?
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Language" : "Sprache"}					
					</Col>
					<Col> {result._source.language} </Col>
				</Row>
			: ""}
			{result._source.url ?
				<Row>
					<Col className="caption" md="3">URL</Col>
					<Col md="9"> {componentUtility.txt2HtmlBr(result._source.url)} </Col>
				</Row>
			: ""}
			{result._source.format ? 
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Format" : "Format"}
					</Col>					
					<Col> {componentUtility.txt2list(result._source.format)} </Col>
				</Row>
			: ""}
			{result._source.rights ? 
				<Row>
					<Col className="caption" md="3">
						{this.state.ownLang === "en" ?
							"Terms of Use" : "Nutzungsbedingungen"}					
					</Col>				
         			<Col md="9"> {componentUtility.txt2HtmlBr(result._source.rights)} </Col>      				
				</Row>
			: ""}

			</Container>
		</div>)
	}
}
export default PublicationItem;