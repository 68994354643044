import './header.scss';

import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Container, Row, Col } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Home, About, Contact, Leibniz, Help, Browse, Search } from './header-components';

import { AdminMenu, AccountMenu, LocaleMenu } from '../menus';
import { CookieBanner } from '@palmabit/react-cookie-law';

export interface IHeaderProps {
	isAuthenticated: boolean;
	isAdmin: boolean;
	ribbonEnv: string;
	isInProduction: boolean;
	isSwaggerEnabled: boolean;
	currentLocale: string;
	onLocaleChange: Function;
}

const Header = (props: IHeaderProps) => {
	const [menuOpen, setMenuOpen] = useState(false);
	
	const [showAuthentication, setShowAuthentication] = useState(false);

	const handleLocaleChange = event => {
		const langKey = event.target.value;
		Storage.session.set('locale', langKey);
		props.onLocaleChange(langKey);
	};

	const renderDevRibbon = () =>
		props.isInProduction === false ? (
			<div className="ribbon dev">
				<a href="">
					<Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
				</a>
			</div>
		) : null;

	const cookies = <CookieBanner
		message="LeibnizOpen uses cookies" // Issue 6
		wholeDomain={true}
		privacyPolicyLinkText="Data Protection" // Issue 6
		policyLink="https://www.gesis.org/en/institute/data-protection" // Issue 6
		onAccept={() => { }}
		statisticsOptionText="Publicly funded research "
		showMarketingOption={false}
		showPreferencesOption={false}
		onAcceptPreferences={() => { }}
		onAcceptStatistics={() => { }}
		styles={{
			dialog: { backgroundColor: 'grey' }
		}}
	/>
	
	const toggleMenu = () => setMenuOpen(!menuOpen);

	/* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

	return (
		<div id="app-header">
			<div className="nav-wrapper">
				<Container>
					{renderDevRibbon()}
					<LoadingBar className="loading-bar" />

					<Navbar dark expand="sm">						
						<NavbarToggler aria-label="Menu" onClick={toggleMenu} /> 
						<Collapse isOpen={menuOpen} navbar>
							<Nav id="header-tabs" className="ml-auto" navbar>
								<Home />
								<Browse />
								<Search />
								{props.isAuthenticated && props.isAdmin && (
									<AdminMenu showSwagger={props.isSwaggerEnabled} showDatabase={!props.isInProduction} />
								)}
								<LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
								{showAuthentication && (
									<AccountMenu isAuthenticated={props.isAuthenticated} /> 
								)}					
								<About />
								<Leibniz />
								<Contact />
								<Help />
							</Nav>
						</Collapse>
					</Navbar>
				</Container>
			</div>

					{cookies}
		</div>
	);
};

export default Header;
